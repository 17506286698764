import React from 'react'
import { Tooltip, Popconfirm } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

import MemoryStore from '../utils/memory-store'
import Enums from '../utils/enums'

import store from '../store'
import { signOut, ActionType } from './core-actions'
import getConfig from '../config/agilite-react-config'

// CUSTOM COMPONENTS
// import DMRImport from '../../dmr-import/dmr-import'
import UserRegistryView from '../apps/user-registry/components/user-registry-view'
import { addTab } from './core-utils'

const CoreReducer = (state = getConfig(), action) => {
  let tmpTabs = null
  let tmpActiveKey = null
  let tmpValue = null
  let tabExists = false

  switch (action.type) {
    case ActionType.MENU_ITEM_CLICK:
      tmpTabs = state.tabNavigation.tabs

      // CUSTOM LOGIC
      switch (action.key) {
        case Enums.tabKeys.USER_REGISTRY:
          tmpValue = {
            key: Enums.tabKeys.OCR_CONFIGS,
            closeable: true,
            title: Enums.titles.USER_REGISTRY,
            content: <UserRegistryView />
          }
          break
        default:
          break
      }

      for (const tab of tmpTabs) {
        if (tab.key === tmpValue.key) {
          tabExists = true
          break
        }
      }

      if (!tabExists) tmpTabs.push(tmpValue)

      return {
        ...state,
        tabNavigation: { ...state.tabNavigation, tabs: tmpTabs, activeKey: action.key },
        leftMenu: { ...state.leftMenu, visible: false },
        rightMenu: { ...state.rightMenu, visible: false }
      }
    case ActionType.LEFT_MENU_OPEN:
    case ActionType.LEFT_MENU_CLOSE:
      return {
        ...state,
        leftMenu: { ...state.leftMenu, visible: !state.leftMenu.visible }
      }
    case ActionType.RIGHT_MENU_OPEN:
    case ActionType.RIGHT_MENU_CLOSE:
      return {
        ...state,
        rightMenu: { ...state.rightMenu, visible: !state.rightMenu.visible }
      }
    case ActionType.TAB_CHANGE:
      return {
        ...state,
        tabNavigation: { ...state.tabNavigation, activeKey: action.key }
      }
    case ActionType.TAB_CLOSE:
      tmpTabs = state.tabNavigation.tabs.filter(tab => tab.key !== action.key)
      tmpActiveKey = tmpTabs.length > 0 ? tmpTabs[tmpTabs.length - 1].key : 'home'

      return {
        ...state,
        tabNavigation: { ...state.tabNavigation, tabs: tmpTabs, activeKey: tmpActiveKey }
      }
    case ActionType.SIGN_IN:
      return {
        ...state,
        tabNavigation: { ...state.tabNavigation, enabled: true },
        leftMenu: { ...state.leftMenu, enabled: true },
        rightMenu: { ...state.rightMenu, enabled: MemoryStore.userProfile.teamRights.role === Enums.values.ROLE_ADMIN },
        toolbar: {
          ...state.toolbar,
          customMenus: {
            content: (
              <Popconfirm title='Are you sure you want to Sign Out?' okText='Yes' cancelText='No' onConfirm={() => store.dispatch(signOut())}>
                <Tooltip title='Sign Out'><LogoutOutlined /></Tooltip>
              </Popconfirm>
            )
          }
        }
      }
    case ActionType.SIGN_OUT:
      return getConfig()
    case ActionType.ADD_TAB:
      return addTab(action.payload.activeKey, action.payload.tab, state)
    default:
      return state
  }
}

export default CoreReducer
