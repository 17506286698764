import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Card, message } from 'antd'

import { v1 } from 'agilite-utils/uuid'

// Actions & Utils
import { getConfigData, addConfigTab } from '../user-registry-utils'
import { setConfigs } from '../user-registry-actions'

import Theme from '../../../resources/theme'
import viewColumns from './ocr-config-view-columns'
import { userRegistryTemplate } from '../../data-templates'

const UserRegistryView = () => {
  const dispatch = useDispatch()
  const ocrConfigs = useSelector(state => state.ocrConfigs)
  const [tableLoading, setTableLoading] = useState(false)

  useEffect(() => {
    handleGetConfigData()
    // eslint-disable-next-line
  }, [])

  const handleGetConfigData = async () => {
    try {
      setTableLoading(true)

      dispatch(setConfigs(await getConfigData()))
      setTableLoading(false)
    } catch (error) {
      message.error(error)
    }
  }

  return (
    <Card>
      <Button
        style={{
          color: Theme.white,
          backgroundColor: Theme.twitterBootstrap.success,
          float: 'left',
          borderRadius: 5,
          marginBottom: 10
        }}
        onClick={() => addConfigTab(true, userRegistryTemplate())}
      >
        Create Configuration
      </Button>
      <Table
        pagination={false}
        columns={viewColumns}
        dataSource={ocrConfigs}
        loading={tableLoading}
        rowKey={() => v1()}
      />
    </Card>
  )
}

export default UserRegistryView
