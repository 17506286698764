import React, { memo } from 'react'
import { Card, Row, Col } from 'antd'
import Logo from '../../resources/logo.png'

const _Home = () => {
  return (
    <Row justify='center' type='flex' style={{ marginTop: 10 }}>
      <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={8}>
        <img src={Logo} alt='Young Engineers' style={{ width: '40%', display: 'block', margin: '0 auto', marginBottom: 15 }} />
        <Card>
          <center>
            <h3>
              Welcome to the {process.env.REACT_APP_NAME}
            </h3>
            Click on the 'burger' menu icon on the top-left corner of this Portal. This will launch the menu drawer which lists all the applications as menu items. To return to this page click on the 'Home' tab in the Tab Bar.
          </center>
        </Card>
      </Col>
    </Row>
  )
}

export default memo(_Home)
