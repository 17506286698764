import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, Input, Select, Card, Button, message, Popconfirm, Spin } from 'antd'

// import { getAgiliteTemplates } from '../../utils'
import { postConfigData, getConfigData, updateConfigData } from '../user-registry-utils'
import { tabClose } from '../../../core/core-actions'

import Theme from '../../../resources/theme'
import { setConfigs } from '../user-registry-actions'
import { userRegistryTemplate } from '../../data-templates'
import { trimObjectValues } from 'agilite-utils'

const UserRegistryForm = (props) => {
  const dispatch = useDispatch()
  const activeKey = useSelector(state => state.core.tabNavigation.activeKey)
  const userData = useSelector(state => state.userRegistry.data)
  // eslint-disable-next-line
  const [data, setData] = useState(props.isNew ? userRegistryTemplate() : props.data)
  const [roleData, setRoleData] = useState([])
  const [formLoading, setFormLoading] = useState(false)

  const handleOnChange = (key, value) => {
    setData(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const handleSubmit = async () => {
    try {
      setFormLoading(true)

      // Cleanup
      await trimObjectValues(data)

      // Validate
      let errMsg = null

      if (!data.key) {
        errMsg = 'Please provide a Key for the Configuration'
      } else if (!data.name) {
        errMsg = 'Please provide a Name for the Configuration'
      } else if (!data.pdfTemplate) {
        errMsg = 'Please select a PDF Template for the Configuration'
      } // Format Key
      data.key = data.key.replace(/[^a-z0-9_-]/gi, '').toLowerCase()

      // Check for duplicates
      userData.map(configuration => {
        if (data.key === configuration.key && props.isNew) errMsg = `An OCR Configuration with key: "${data.key}" already exists`
        if (data.name === configuration.name && data._id !== configuration._id) errMsg = `An OCR Configuration with name: "${data.name}" already exists`
        return null
      })

      if (errMsg) {
        setFormLoading(false)
        return message.error(errMsg)
      }

      if (props.isNew) {
        await postConfigData(data)
      } else {
        await updateConfigData(data)
      }

      dispatch(setConfigs(await getConfigData()))
      dispatch(tabClose(activeKey))
      setFormLoading(false)
    } catch (error) {
      setFormLoading(false)
      message.error(error)
    }
  }

  useEffect(() => {
    const getTemplates = async () => { // TODO:
      try {
        // setRoleData(await getAgiliteTemplates())
      } catch (e) {
        message.error(e)
      }
    }

    getTemplates()
  }, [])

  return (
    <div>
      <Row justify='center'>
        <Col span={10}>
          <Card title='User Profile' type='inner'>
            <Spin spinning={formLoading}>
              <Row justify='space-between'>
                <Col xs={20} sm={20} md={20} lg={14} xl={14} xxl={14}>
                  <h3>General</h3>
                  <Form>
                    <Form.Item>
                      <span style={{ color: Theme.twitterBootstrap.danger }}>*</span> First Name
                      <Input
                        onChange={e => handleOnChange('firstName', e.target.value)}
                        defaultValue={props.data.firstName}
                        required
                      />
                    </Form.Item>
                    <Form.Item>
                      <span style={{ color: Theme.twitterBootstrap.danger }}>*</span> Last Name
                      <Input
                        onChange={e => handleOnChange('lastName', e.target.value)}
                        defaultValue={props.data.lastName}
                        required
                      />
                    </Form.Item>
                    <Form.Item>
                      <span style={{ color: Theme.twitterBootstrap.danger }}>*</span> Role
                      <Select
                        onChange={value => handleOnChange('role', value)}
                        defaultValue={props.data.role}
                      >
                        <Select.Option value=''>-Select-</Select.Option>
                        {roleData.map(profile => {
                          return <Select.Option key={profile.data.label} value={profile.data.label}>{profile.data.label}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      Address Details
                      <Input.TextArea
                        onChange={e => handleOnChange('address', e.target.value)}
                        defaultValue={props.data.address}
                        rows={3}
                      />
                    </Form.Item>
                    <Form.Item>
                      Optional Notes
                      <Input.TextArea
                        onChange={e => handleOnChange('notes', e.target.value)}
                        defaultValue={props.data.notes}
                        rows={5}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{ backgroundColor: Theme.twitterBootstrap.success, color: Theme.white, width: 100 }}
                        htmlType='submit'
                        disabled={formLoading}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <Popconfirm
                        disabled={formLoading}
                        title='Are you sure you want to Cancel?'
                        onConfirm={() => dispatch(tabClose(activeKey))}
                      >
                        <Button
                          style={{ backgroundColor: Theme.twitterBootstrap.danger, color: Theme.white, marginLeft: 10 }}
                          disabled={formLoading}
                        >
                          Cancel
                        </Button>
                      </Popconfirm>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Spin>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default UserRegistryForm
