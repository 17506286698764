import React from 'react'
import { ToolOutlined, SettingOutlined, AppstoreOutlined, UsergroupAddOutlined } from '@ant-design/icons'

// Redux
import { menuItemClick, leftMenuOpen, leftMenuClose, rightMenuOpen, rightMenuClose, tabChange, tabClose } from '../core/core-actions'
import store from '../store'

// Components
import CoreHome from '../core/components/core-home'
import CoreSignIn from '../core/components/core-sign-in'

import Enums from '../utils/enums'

const getConfig = () => {
  return {
    rootContent: <CoreSignIn />,
    theme: {},
    leftMenu: {
      enabled: false,
      title: process.env.REACT_APP_NAME,
      visible: false,
      expandedMenuItems: [Enums.tabKeys.APPS],
      menuItems: [
        {
          key: Enums.tabKeys.APPS,
          title: <><AppstoreOutlined /> {Enums.titles.APPLICATIONS}</>,
          children: [
            {
              key: Enums.tabKeys.USER_REGISTRY,
              title: <><ToolOutlined /> {Enums.titles.USER_REGISTRY}</>
            }
          ]
        }
      ],
      handleMenuItemClick: (e) => store.dispatch(menuItemClick(e)),
      onClose: (e) => store.dispatch(leftMenuClose(e)),
      onOpen: (e) => store.dispatch(leftMenuOpen(e))
    },
    rightMenu: {
      enabled: false,
      title: Enums.titles.PORTAL_ADMINISTRATION,
      visible: false,
      menuItems: [
        {
          key: Enums.tabKeys.USER_REGISTRY,
          title: <><ToolOutlined /> {Enums.titles.USER_REGISTRY}</>,
          children: []
        }
      ],
      expandedMenuItems: [],
      handleMenuItemClick: (e) => store.dispatch(menuItemClick(e)),
      onClose: (e) => store.dispatch(rightMenuClose(e)),
      onOpen: (e) => store.dispatch(rightMenuOpen(e))
    },
    tabNavigation: {
      enabled: false,
      activeKey: Enums.tabKeys.HOME,
      animated: false,
      onTabChange: (key) => store.dispatch(tabChange(key)),
      onTabClose: (key, action) => store.dispatch(tabClose(key, action)),
      rootTabContent: <CoreHome />,
      rootTabKey: Enums.tabKeys.HOME,
      rootTabTitle: Enums.titles.HOME,
      tabs: []
    },
    toolbar: {
      enabled: true,
      title: process.env.REACT_APP_NAME,
      customMenus: {
        content: null
      }
    }
  }
}

export default getConfig
