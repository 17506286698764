import Agilite from 'agilite'
import Axios from 'agilite-utils/axios'

import MemoryStore from '../utils/memory-store'
import Enums from '../utils/enums'

export const signInUser = () => {
  return new Promise((resolve, reject) => {
    (async function () {
      const entry = MemoryStore.userProfile
      let errMsg = null
      let result = null

      try {
        // Format Header Props
        entry.email = entry.email.toLowerCase()
        entry[Enums.headers.TEAM_ID] = process.env.REACT_APP_AGILITE_TEAM_ID

        // Authenticate User
        result = await Axios.get(`${process.env.REACT_APP_AGILITE_API_SERVER_URL}${Enums.endpoints.AUTH_USER}`, { headers: { ...entry } })

        MemoryStore.agilite = new Agilite({
          apiServerUrl: process.env.REACT_APP_AGILITE_API_SERVER_URL,
          apiKey: result.data.apiKey
        })

        MemoryStore.apiKey = result.data.apiKey
        delete result.data.apiKey

        MemoryStore.userProfile = result.data

        resolve()
      } catch (err) {
        console.log(err)

        if (err.response) {
          errMsg = err.response.data.errorMessage
        } else if (err.message) {
          errMsg = err.message
        } else {
          errMsg = Enums.messages.UNKNOWN_ERROR
        }

        reject(errMsg)
      }
    })()
  })
}

export const addTab = (activeKey, tab, state) => {
  let tabExists = false
  let result = null

  for (const tmpEntry of state.tabNavigation.tabs) {
    if (tmpEntry.key === activeKey) {
      tabExists = true
      break
    }
  }

  if (tabExists) {
    result = {
      ...state,
      tabNavigation: { ...state.tabNavigation, activeKey }
    }
  } else {
    state.tabNavigation.tabs.push(tab)

    result = {
      ...state,
      tabNavigation: { ...state.tabNavigation, activeKey, tabs: state.tabNavigation.tabs }
    }
  }

  return result
}
