import Enums from '../utils/enums'

export const userRegistryTemplate = () => {
  return {
    isActive: true,
    firstName: Enums.values.STRING_EMPTY,
    lastName: Enums.values.STRING_EMPTY,
    dateOfBirth: null,
    address: Enums.values.STRING_EMPTY,
    notes: Enums.values.STRING_EMPTY,
    role: Enums.values.STRING_EMPTY,
    dateCreated: null,
    dateModified: null,
    createdBy: Enums.values.STRING_EMPTY,
    modifiedBy: Enums.values.STRING_EMPTY
  }
}
