import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import { Provider, useSelector } from 'react-redux'
import { AgiliteReact } from 'agilite-react'
import store from './store'

import './resources/style.css'
import Enums from './utils/enums'

// Setup root component with configured agilite-react state
const App = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      (e || window.event).returnValue = 'Are you sure you want to leave this page?'
      return 'Are you sure you want to leave this page?'
    })
  }, [])

  // state.core is created using agilite-react-config.js in config folder
  const config = useSelector(state => state.core)
  return <AgiliteReact config={config} />
}

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById(Enums.values.ROOT_TAG))
