const ActionType = {
  SET_CONFIGS: 'SET_CONFIGS'
}

const setConfigs = (data) => {
  return { type: ActionType.SET_CONFIGS, payload: data }
}

export {
  setConfigs,
  ActionType
}
