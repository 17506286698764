import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'
import OCRConfigForm from './components/user-registry-form'
import CoreState from '../../store'
import { addTab } from '../../core/core-actions'

export const getConfigData = () => {
  return new Promise((resolve, reject) => {
    (async function () {
      let result = null
      let errMsg = null

      try {
        result = await MemoryStore.agilite.Connectors.execute(Enums.profileKeys.OCR_CONFIGS, Enums.routeKeys.READ)
        resolve(result.data)
      } catch (err) {
        console.log(err)

        if (err.response) {
          errMsg = (err.response.data.errorMessage)
        } else if (err.message) {
          errMsg = (err.message)
        } else {
          errMsg = (Enums.messages.UNKNOWN_ERROR)
        }

        reject(errMsg)
      }
    })()
  })
}

export const postConfigData = (configData) => {
  return new Promise((resolve, reject) => {
    (async function () {
      const data = {}
      let result = null
      let errMsg = null

      try {
        data.data = JSON.stringify(configData)

        result = await MemoryStore.agilite.Connectors.execute(Enums.profileKeys.OCR_CONFIGS, Enums.routeKeys.CREATE, data)
        resolve(result.data)
      } catch (err) {
        console.log(err)

        if (err.response) {
          errMsg = (err.response.data.errorMessage)
        } else if (err.message) {
          errMsg = (err.message)
        } else {
          errMsg = (Enums.messages.UNKNOWN_ERROR)
        }

        reject(errMsg)
      }
    })()
  })
}

export const updateConfigData = (configData) => {
  return new Promise((resolve, reject) => {
    (async function () {
      const recordId = configData._id
      let errMsg = null

      delete configData._id

      try {
        await MemoryStore.agilite.Connectors.execute(
          Enums.profileKeys.OCR_PROCESSES,
          Enums.routeKeys.UPDATE_MANY,
          { filter: JSON.stringify({ isDraft: true, 'ocrConfig.key': configData.key }), data: JSON.stringify({ ocrConfig: configData }) }
        )

        await MemoryStore.agilite.Connectors.execute(
          Enums.profileKeys.OCR_CONFIGS,
          Enums.routeKeys.UPDATE,
          { recordId, data: JSON.stringify(configData) }
        )
        resolve()
      } catch (err) {
        console.log(err)

        if (err.response) {
          errMsg = (err.response.data.errorMessage)
        } else if (err.message) {
          errMsg = (err.message)
        } else {
          errMsg = (Enums.messages.UNKNOWN_ERROR)
        }

        reject(errMsg)
      }
    })()
  })
}

export const deleteConfigData = (recordId, configKey) => {
  return new Promise((resolve, reject) => {
    (async function () {
      let result = null
      let errMsg = null

      try {
        result = await MemoryStore.agilite.Connectors.execute(Enums.profileKeys.OCR_PROCESSES, Enums.routeKeys.READ, { filter: JSON.stringify({ isDraft: true, 'ocrConfig.key': configKey }) })

        if (result.data && result.data.length > 0) {
          errMsg = 'This OCR Configuration cannot be deleted as it is in use by a Draft Process'
          reject(errMsg)
        } else {
          await MemoryStore.agilite.Connectors.execute(Enums.profileKeys.OCR_CONFIGS, Enums.routeKeys.DELETE, { recordId })
          resolve()
        }
      } catch (err) {
        console.log(err)

        if (err.response) {
          errMsg = (err.response.data.errorMessage)
        } else if (err.message) {
          errMsg = (err.message)
        } else {
          errMsg = (Enums.messages.UNKNOWN_ERROR)
        }

        reject(errMsg)
      }
    })()
  })
}

export const addConfigTab = (isNew, data) => {
  const tmpTab = {
    key: isNew ? Enums.tabKeys.NEW_OCR_CONFIG : data.name,
    closeable: true,
    title: isNew ? Enums.titles.NEW_OCR_CONFIG : `OCR Config: ${data.name}`,
    content: (
      <OCRConfigForm
        isNew={isNew}
        data={data}
      />
    )
  }

  CoreState.dispatch(addTab(isNew ? Enums.tabKeys.NEW_OCR_CONFIG : data.name, tmpTab))
}