import React from 'react'
import { message, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import Theme from '../../../resources/theme'

// Actions
import { setConfigs } from '../user-registry-actions'

// Store
import store from '../../../store'
import { addConfigTab, deleteConfigData, getConfigData } from '../user-registry-utils'

const viewColumns = [
  {
    key: 'name',
    title: 'Configuration Name',
    dataIndex: 'name',
    width: '20%',
    render: (text, record) => {
      // eslint-disable-next-line
      return <a onClick={() => addConfigTab(false, record)}>{record.name}</a>
    }
  },
  {
    key: 'key',
    title: 'Configuration Key',
    dataIndex: 'key',
    width: '20%'
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    width: '40%'
  },
  {
    key: 'pdfTemplate',
    title: 'PDF Template',
    dataIndex: 'pdfTemplate'
  },
  {
    key: 'actions',
    title: 'Actions',
    width: '5%',
    render: (text, record) => {
      return (
        <Popconfirm
          title='Are you sure you want to Delete this Configuration?'
          okText='Yes'
          cancelText='No'
          onConfirm={async () => {
            try {
              await deleteConfigData(record._id, record.key)
              store.dispatch(setConfigs(await getConfigData()))
            } catch (error) {
              message.error(error)
            }
          }}
        >
          <DeleteOutlined
            style={{ cursor: 'pointer', color: Theme.twitterBootstrap.danger }}
          />
        </Popconfirm>
      )
    }
  }
]

export default viewColumns
