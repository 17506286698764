const ActionType = {
  MENU_ITEM_CLICK: 'MENU_ITEM_CLICK',
  LEFT_MENU_CLOSE: 'LEFT_MENU_CLOSE',
  LEFT_MENU_OPEN: 'LEFT_MENU_OPEN',
  RIGHT_MENU_CLOSE: 'RIGHT_MENU_CLOSE',
  RIGHT_MENU_OPEN: 'RIGHT_MENU_OPEN',
  TAB_CHANGE: 'TAB_CHANGE',
  TAB_CLOSE: 'TAB_CLOSE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  ADD_TAB: 'ADD_TAB'
}

const menuItemClick = (e) => {
  return { type: ActionType.MENU_ITEM_CLICK, key: e.key }
}

const leftMenuOpen = (e) => {
  return { type: ActionType.LEFT_MENU_OPEN }
}

const leftMenuClose = (e) => {
  return { type: ActionType.LEFT_MENU_CLOSE }
}

const rightMenuOpen = (e) => {
  return { type: ActionType.RIGHT_MENU_OPEN }
}

const rightMenuClose = (e) => {
  return { type: ActionType.RIGHT_MENU_CLOSE }
}

const tabChange = (key) => {
  return { type: ActionType.TAB_CHANGE, key }
}

const tabClose = (key) => {
  return { type: ActionType.TAB_CLOSE, key }
}

const signIn = () => {
  return { type: ActionType.SIGN_IN }
}

const signOut = () => {
  return { type: ActionType.SIGN_OUT }
}

const addTab = (activeKey, tab) => {
  return {
    type: ActionType.ADD_TAB,
    payload: {
      activeKey,
      tab
    }
  }
}

export {
  menuItemClick,
  leftMenuOpen,
  leftMenuClose,
  rightMenuOpen,
  rightMenuClose,
  tabChange,
  tabClose,
  signIn,
  signOut,
  addTab,
  ActionType
}
