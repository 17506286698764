const Enums = {
  values: {
    ENV_PRODUCTION: 'production',
    ROOT_TAG: 'root',
    STRING_EMPTY: '',
    ROLE_ADMIN: 'Admin'
  },
  reqMethods: {
    POST: 'post',
    PUT: 'put',
    GET: 'get',
    DELETE: 'delete'
  },
  endpoints: {
    AUTH_USER: '/auth/user',
    READ_USER_DATA: '/api/ocr/readUserData',
    UPLOAD_USER_DATA: '/api/ocr/uploadUserData',
    UPDATE_STUB_EVENTS: '/api/ocr/updateStubEvents',
    EXPORT_USER_STUBS: '/api/ocr/exportUserStubs',
    GET_RECORD_STATE: '/api/ocr/getRecordState',
    DMR_IMPORT: '/api/dmr/import'
  },
  headers: {
    API_KEY: 'api-key',
    CONTENT_TYPE: 'Content-Type',
    TEAM_ID: 'team-id'
  },
  messages: {
    UNKNOWN_ERROR: 'An unknown error occurred. The Agilit-e service may not be available'
  },
  tabKeys: {
    NEW_USER_REGISTRY: 'new_user_registry',
    USER_REGISTRY: 'user_registry',
    HOME: 'home',
    APPS: 'apps'
  },
  titles: {
    USER_REGISTRY: 'User Registry',
    HOME: 'Home',
    PORTAL_ADMINISTRATION: 'Portal Administration',
    APPLICATIONS: 'Applications',
    PORTAL_MENU: 'Portal Menu'
  },
  profileKeys: {
    OCR_CONFIGS: 'ocr_configs',
    OCR_PROCESSES: 'ocr_processes',
    OCR_STUBS: 'ocr_stubs'
  },
  routeKeys: {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    UPDATE_MANY: 'update_many',
    DELETE: 'delete',
    DELETE_MANY: 'delete_many',
    BULK_WRITE: 'bulk_write'
  }
}

export default Enums
