const memoryStore = {
  agilite: null,
  apiKey: '',
  activeEntries: {},
  userProfile: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    teamId: ''
  }
}

export default memoryStore
